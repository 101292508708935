import './App.css';
import './css/login.css'
import './css/homePage.css'
import './css/leisure.css'
import './css/newLeisureForm.css'
import './css/newHubForm.css'
import './css/hubInviteForm.css'
import './css/leisureExtend.css'
import './css/user.css'
import './css/confirmation.css'
import './css/beugsFeatures.css'
import './css/members.css'
import './css/option.css'
import './css/feedbackForChris.css'
import './css/loading.css'
import './css/hubInvitePage.css'
import './css/index.css'
import './css/notFoundPage.css'
import './css/privacyPolicy.css'
import './css/header.css'
import './css/circlesBackground.css'
import { NotFoundPage } from './routes/notFoundPage';
import { User } from './types/user';
import { serverManager } from './controller/serverManager';
import { check_iOS } from './utils';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { IndexPage } from './routes';
import { HubInvitePage } from './routes/hubInvitePage';
import { PrivacyPolicy } from './routes/privacyPolicy';
import { Header } from './components/header';

export async function updateUser(user: User) {
  localStorage.setItem("user", JSON.stringify(user))
  await serverManager.updateFirestoreUser(user)
}

export var deviceIos = check_iOS()
export var deviceIsMobile = window.screen.width > 600 ? false : true

function App() {

  return (
    <Router>
      <Header />


      <Routes>
        <Route path="/" Component={IndexPage} />
        <Route path="/invite/*" Component={HubInvitePage} />
        <Route path="/privacypolicy" Component={PrivacyPolicy} />
        <Route path="/*" Component={NotFoundPage} />
      </Routes>
    </Router>
  );
}

export default App;
/*TODO

http://react-responsive-carousel.js.org/storybook/index.html?path=/story/01-basic--base&knob-showArrows_Toggles=&knob-showStatus_Toggles=&knob-showIndicators_Toggles=&knob-infiniteLoop_Toggles=true&knob-showThumbs_Toggles=&knob-useKeyboardArrows_Toggles=true&knob-autoPlay_Toggles=&knob-stopOnHover_Toggles=true&knob-swipeable_Toggles=true&knob-dynamicHeight_Toggles=&knob-emulateTouch_Toggles=true&knob-autoFocus_Toggles=&knob-thumbWidth_Values=100&knob-selectedItem_Values=0&knob-interval_Values=2000&knob-transitionTime_Values=500&knob-swipeScrollTolerance_Values=5


- Creation de compte from scratch -- DONE
- Cration de Hub -- DONE
- Creation de leisure --DONE
- Inviet mail friend se trigger à la creation d'un hub -- DONE
- Condition affichage bouton loisir aleatoire -- DONE
- Affichage desktop list des leisure quand on a qu'une category -- DONE
- Faire une activité doit refresh les activité -- DONE

- Fermeture popup invite friend apres la validation -- DONE
- Ne recup pas les informations apres une invitations -- DONE
- Tuto du site -- DONE

- Btn de fermeture de leisure -- Done
- Rajouter la photo de profile de l'utilisateur -- Done
- Retirer scroll horizontal format mobile -- Done
- Css pour safari -- Done
- Edit de Leisure -- Done
- Delete param dans l'url apres la connexion
- Supressoin de leisure -- Done
- Quitter un hub -- Done
- Supprimer un hub -- Done
- Affichage boutton random quand on a 0 loisir -- Done
- Ne plus delete le btn de feedback -- Done
- Remettre la possibilité de s'authentifier avec gmail en plus de la methode classique -- Done
- Delete btn suivant ou precedant quant t'arrives à la premier ou derniere page
- Affichage de la liste des membres du hub
- Afficher le nom du createur et pas son UID

---- CSS -----
- Bouton aleatoire dans les categories ( les rendre plus petit qu'un leisure) -- DONE
- Espacement des leisure -- DONE
- Couleur de leisure fait/non fait -- DONE
- Design du leisureExtended  -- DONE
- La liste d'option de la selection de hub s'affiche nimporte ou -- DONE
- Desgin bnt inviter un ami -- DONE
- Design formulaire invitation à un hub -- DONE
- Design mail d'invit -- DONE
*/

