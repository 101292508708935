export function frenchDate(timestamp: number) {
    let date = new Date(timestamp)
    let day = date.getUTCDate() > 9 ? date.getUTCDate() : "0" + date.getUTCDate()
    let month = (date.getUTCMonth() + 1) > 9 ? (date.getUTCMonth() + 1) : "0" + (date.getUTCMonth() + 1)
    return `${day}/${month}/${date.getFullYear()}`
}

export function getRandom(min: number, max: number) {
    return Math.random() * (max - min) + min;
}

export function validateEmail(mail: string) {
    let mailformat = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (mail.match(mailformat)) {
        return true;
    }
    else {
        return false;
    }
}

export function check_iOS() {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ].includes(navigator.platform)
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}
