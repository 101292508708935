import leisureTimeLogo from "../assets/hobbiesLogo.png";
import { useTranslation } from "react-i18next";
export function Header() {
    const { t } = useTranslation();
    return (
        <header>
            <a href="/">
                <div className='logoAndTitleContainer'>
                    <img src={leisureTimeLogo} alt='logo leisure time' className='leisureTimeLogo' />
                    <h2>Leisure Time</h2>
                </div>
            </a>


            <div className="centerButton">
                <a href="https://onelink.to/akm6jf" className="downloadButton">{t('buttons.download')}</a>
            </div>
        </header>
    );
}