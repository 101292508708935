import { User } from "../types/user"
import { Hub } from "../types/hub";
import { Leisure } from "../types/leisure";
import { mailParam } from "../types/mail";
import { Feedback } from "../types/feedback";
import { getFunctions, httpsCallable } from "firebase/functions";

// dev : dev/dev
// prod : ""
const environnement = "dev/dev"

export class serverManagerClass {

  async getUsers(memberIds: string[]) {
    const getUsers = httpsCallable(getFunctions(), 'getUsers');
    let queryParam = {
      memberIds: memberIds,
      environnement: environnement
    }
    return getUsers(queryParam).then((result) => {
      return result.data as User;
    }).catch((error) => {
      console.log(error.code, error.message, error.details);
    }) as unknown as User[]
  }

  async deleteHub(hubId: string) {
    const deleteHub = httpsCallable(getFunctions(), 'deleteHub');
    let queryParam = {
      hubId: hubId,
      environnement: environnement
    }
    return deleteHub(queryParam).then((result) => {
      return result.data;
    }).catch((error) => {
      console.log(error.code, error.message, error.details);
    })
  }

  async leaveHub(hubId: string, userUid: string) {
    const leaveHub = httpsCallable(getFunctions(), 'leaveHub');
    let queryParam = {
      hubId: hubId,
      userUid: userUid,
      environnement: environnement
    }
    return leaveHub(queryParam).then((result) => {
      return result.data;
    }).catch((error) => {
      console.log(error.code, error.message, error.details);
    })
  }

  async deleteLeisure(id: string) {
    const deleteLeisure = httpsCallable(getFunctions(), 'deleteLeisure');
    let queryParam = {
      leisureId: id,
      environnement: environnement
    }
    return deleteLeisure(queryParam).then((result) => {
      return result.data;
    }).catch((error) => {
      console.log(error.code, error.message, error.details);
    })
  }

  async sendFeedBack(feedback: Feedback) {
    const sendFeedBack = httpsCallable(getFunctions(), 'sendFeedBack');
    let queryParam = {
      feedback: feedback,
      environnement: environnement
    }
    return sendFeedBack(queryParam).then((result) => {
      return result.data;
    }).catch((error) => {
      console.log(error.code, error.message, error.details);
    })
  }

  async updateFirestoreUser(dataUser: User) {
    const updateFirestoreUser = httpsCallable(getFunctions(), 'updateFirestoreUser');
    let queryParam = {
      user: dataUser,
      environnement: environnement
    }
    return updateFirestoreUser(queryParam).then((result) => {
      return result.data;
    }).catch((error) => {
      console.log(error.code, error.message, error.details);
    }) as unknown as User
  }

  async getAllHub(uid: string) {
    const getAllHub = httpsCallable(getFunctions(), 'getAllHub');
    let queryParam = {
      uid: uid,
      environnement: environnement
    }
    return getAllHub(queryParam).then((result) => {
      return result.data
    }).catch((error) => {
      console.log(error.code, error.message, error.details)
    }) as unknown as Hub[]
  }

  async getHub(id: string) {
    const getHub = httpsCallable(getFunctions(), 'getHub');
    let queryParam = {
      hubId: id,
      environnement: environnement
    }
    return getHub(queryParam).then((result) => {
      return result.data
    }).catch((error) => {
      console.log(error.code, error.message, error.details)
    }) as unknown as Hub
  }

  async createHub(hub: Hub) {
    const createHub = httpsCallable(getFunctions(), 'createHub');
    let queryParam = {
      hub: hub,
      environnement: environnement
    }
    return createHub(queryParam).then((result) => {
      return result.data
    }).catch((error) => {
      console.log(error.code, error.message, error.details)
    }) as unknown as Hub
  }

  async createLeisure(leisure: Leisure) {
    const createLeisure = httpsCallable(getFunctions(), 'createLeisure');
    let queryParam = {
      leisure: leisure,
      environnement: environnement
    }
    return createLeisure(queryParam).then((result) => {
      return result.data as Leisure[]
    }).catch((error) => {
      console.log(error.code, error.message, error.details)
    });
  }

  async getAllLeisures(hubId: string) {
    const getAllLeisures = httpsCallable(getFunctions(), 'getAllLeisures');
    let queryParam = {
      hubId: hubId,
      environnement: environnement
    }
    return getAllLeisures(queryParam).then((result) => {
      return result.data as Leisure[]
    }).catch((error) => {
      console.log(error.code, error.message, error.details)
    });
  }

  async updateLeisure(leisure: Leisure) {
    const updateLeisure = httpsCallable(getFunctions(), 'updateLeisure');
    let queryParam = {
      leisure: leisure,
      environnement: environnement
    }
    return updateLeisure(queryParam).then((result) => {
    }).catch((error) => {
      console.log(error.code, error.message, error.details)
    });
  }

  async inviteFriend(param: mailParam) {
    const inviteFriend = httpsCallable(getFunctions(), 'inviteFriend');
    let queryParam = {
      param: param,
      environnement: environnement
    }
    return inviteFriend(queryParam).then((result) => {
    }).catch((error) => {
      console.log(error.code, error.message, error.details)
    });
  }

  async sendMailNewLeisure(param: mailParam) {
    const sendMailNewLeisure = httpsCallable(getFunctions(), 'sendMailNewLeisure');
    let queryParam = {
      param: param,
      environnement: environnement
    }
    return sendMailNewLeisure(queryParam).then((result) => {
    }).catch((error) => {
      console.log(error.code, error.message, error.details)
    });

  }

  async joinHub(user: User, hubId: string) {
    const joinHub = httpsCallable(getFunctions(), 'joinHub');
    let queryParam = {
      user: user,
      hubId: hubId,
      environnement: environnement
    }
    return joinHub(queryParam).then((result) => {
    }).catch((error) => {
      console.log(error.code, error.message, error.details)
    });
  }

  async fetchFeedback() {
    const fetchFeedback = httpsCallable(getFunctions(), 'fetchFeedback');
    return fetchFeedback().then((result) => {
      return result.data as Feedback[]
    }).catch((error) => {
      console.log(error.code, error.message, error.details)
    });

  }

}

export const serverManager = new serverManagerClass()

