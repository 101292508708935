import notFoundImg from '../assets/notFound.png';

export function NotFoundPage() {
    return (
        <div className='notFoundPageContainer'>
            <div className='notFoundImgContainer'>
                <img src={notFoundImg} width={200} alt="404" />
            </div>
        </div>
    )
}