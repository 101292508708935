
import { useEffect, useState } from 'react'
import friend from '../assets/friends.png'
import expired from '../assets/expired.png'
import joinHub from '../assets/joinHub.png'
import { useTranslation } from 'react-i18next'


export function HubInvitePage() {
    const { t } = useTranslation();
    const [inviteLinkCopied, setInviteLinkCopied] = useState<boolean>(false)
    const queryString = window.location.search
    const urlParam = new URLSearchParams(queryString).get('hub')
    const hubName = new URLSearchParams(queryString).get('hubName')
    const userName = new URLSearchParams(queryString).get('userName')
    const timestamp = +(urlParam?.split("_").pop() ?? 0)
    const hubAndUserId = urlParam?.split("_").shift()?.slice(8, -8)
    const hubId = hubAndUserId?.split("-").shift()

    function validateTimestamp(timestamp: number): boolean {
        if (timestamp === 0) {
            return false;
        }
        const oneDayAgo = Math.floor(Date.now() / 1000) - 24 * 60 * 60;
        if (timestamp < oneDayAgo) {
            return false;
        }
        return true;
    }

    const inviteExpired = !validateTimestamp(timestamp)

    //https://leisuretime.appverse.online/invite/?hub=OXXNzcuxLY8npcgATHZH

    //https://leisuretime.appverse.online/invite/?hub=f47mt3YjOXXNzcuxLY8npcgATHZH-wPZ4zJOSFXOhpBxR706zmXwA72u1YQHJ3ODK_1726062485838&userName=King Chris&hubName=Plan Hub

    function checkMobileDevice(userAgent: string) {
        const iPhoneRegex = /iPhone/i;
        const androidRegex = /Android/i;
        const mobileRegex = /Mobile/i;

        if (iPhoneRegex.test(userAgent) && mobileRegex.test(userAgent)) {
            return 'iPhone';
        } else if (androidRegex.test(userAgent) && mobileRegex.test(userAgent)) {
            return 'Android';
        } else {
            return false;
        }
    }
    const userAgent = navigator.userAgent;
    const deviceType = checkMobileDevice(userAgent);

    useEffect(() => {
        setTimeout(() => {
            if (inviteLinkCopied) {
                setInviteLinkCopied(false)
            }

        }, 2000);
    }, [inviteLinkCopied])


    function goToApp() {
        setInviteLinkCopied(true)
        navigator.clipboard.writeText(`https://leisuretime.appverse.online/invite/?hub=${hubId}`)

        if (deviceType === 'iPhone') {
            //window.location.replace(`leisureTimeMobile://?hub=${hubId}`)
        }

        if (deviceType === 'Android') {
            //window.location.replace(`leisureTimeMobile://?hub=${hubId}`)
            //window.location.replace(`https://leisuretime.appverse.online/invite/?hub=${hubId}`)
        }

    }

    return (
        <div className='HubInvitePageContainer'>

            <div className='HubInviteDivContainer'>

                {inviteExpired ?
                    <div className='hubInviteExpired'>
                        <img src={expired} alt='expired' className='hubInvitefriendsLogo'></img>
                        <p>{t('invitePage.urlExpired')}</p>
                    </div>
                    :

                    <>
                        <img src={friend} alt='friends' className='hubInvitefriendsLogo'></img>
                        <div className='HubInviteUserContainer'>
                            <b>{decodeURIComponent(userName || "")}</b>
                        </div>
                        <p>{t('invitePage.invite')} <b>{decodeURIComponent(hubName || "")}</b></p>


                        <button className='joinHubBtn' onClick={goToApp}>{t('invitePage.joinHub')}</button>

                        <>
                            {inviteLinkCopied && <p className='inviteLinkCopied'>{t('invitePage.urlCopied')}</p>}
                            <img className='joinHubImg' src={joinHub} alt='tuto rejoindre hub'></img>
                        </>

                    </>
                }

            </div>

        </div>
    )
}