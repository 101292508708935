import React from 'react';
import { useTranslation } from 'react-i18next';

export function PrivacyPolicy() {
    const { t } = useTranslation();

    return (
        <div className="container">
            <h1 className="header">{t('privacyPolicy.title')}</h1>

            <p className="paragraph">
                {t('privacyPolicy.description')}
            </p>

            <h2 className="header">{t('privacyPolicy.informationCollected')}</h2>

            <p className="paragraph">
                {t('privacyPolicy.informationCollectedDescription')}
            </p>

            <ul className="list">
                <li className="listItem">{t('privacyPolicy.userProvidedInfo')}</li>
                <li className="listItem">{t('privacyPolicy.usageInfo')}</li>
            </ul>

            <h2 className="header">{t('privacyPolicy.informationSharing')}</h2>

            <p className="paragraph">
                {t('privacyPolicy.informationSharingDescription')}
            </p>

            <h2 className="header">{t('privacyPolicy.informationSecurity')}</h2>

            <p className="paragraph">
                {t('privacyPolicy.informationSecurityDescription')}
            </p>

            <h2 className="header">{t('privacyPolicy.policyChanges')}</h2>

            <p className="paragraph">
                {t('privacyPolicy.policyChangesDescription')}
            </p>
            <p className="paragraph">
                {t('privacyPolicy.consent')}
            </p>
        </div>
    );
}